import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.states';
import { Honeycomb } from 'src/app/services/honeycomb-api/honeycomb-api';
import { availableCols, getVisibleCols } from '../task-common';
import { TaskDetailComponent } from '../task-detail/task-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { TaskAgenda } from 'src/app/api/tasks/my-tasks/my-tasks.state';

import twotoneIndeterminateCheckBox from '@iconify/icons-ic/twotone-indeterminate-check-box';
import twotoneCheckBoxOutlineBlank from '@iconify/icons-ic/twotone-check-box-outline-blank';
import roundDoneOutline from '@iconify/icons-ic/round-done-outline';
import outlineMessage from '@iconify/icons-ic/outline-message';
import twotoneMessage from '@iconify/icons-ic/twotone-message';
import twotoneBuildCircle from '@iconify/icons-ic/twotone-build';
import twotoneCheckBox from '@iconify/icons-ic/twotone-check-box';
import certificateIcon from '@iconify/icons-fa-solid/certificate';
import roundSubdirectoryArrowRight from '@iconify/icons-ic/round-subdirectory-arrow-right';
import roundRepeat from '@iconify/icons-ic/round-repeat';
import { BuildConfigService } from 'src/app/services/build-config.service';
import { TasksService } from 'src/app/services/tasks.service';
import { isNullOrUndefined } from 'src/app/common/functions';

@Component({
  selector: 'tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  tasks: BehaviorSubject<Array<Honeycomb.Tenant.Tasker.IService.Model.Task>> = new BehaviorSubject([]);

  attributes: { [id: string]: Array<Honeycomb.Tenant.LookupTables.IService.CodeValue> } = {};

  @Input()
  viewSort: Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort;

  @Input()
  agenda: TaskAgenda;

  @Output()
  sortChanged: EventEmitter<Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort> = new EventEmitter();

  @Output()
  pageChanged: EventEmitter<number> = new EventEmitter();

  @Output()
  selected: EventEmitter<number> = new EventEmitter();

  @Input()
  stickyHorizontalScroll = false;

  @Input()
  agendaKey: string = 'table';

  dataSource = new MatTableDataSource<Honeycomb.Tenant.Tasker.IService.Model.Task>([]);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageScroll = 0;
  subscriptions: Array<Subscription> = [];

  filter: Honeycomb.Tenant.Tasker.IService.TaskListFilter;
  taskType = Honeycomb.Common.Enums.TaskType;

  taskRelation = Honeycomb.Common.Enums.TaskRelation;
  taskState = Honeycomb.Common.Enums.TaskState;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  
  pageIndex$: Observable<number>;
  pageIndex: number;

  pageSize$: Observable<number> = of(50);
  pageSize: number = 50;

  itemsTotal: Number = 1000;

  showPaginator: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private scrolling = false;
  private mouseScroll = false;
  private isTouch = 'ontouchstart' in window || (navigator as any).msMaxTouchPoints;

  get displayedColumns(): string[] {
    return getVisibleCols(this.agenda, this.buildConfigService);
  }

  twotoneIndeterminateCheckBox = twotoneIndeterminateCheckBox;
  twotoneCheckBoxOutlineBlank = twotoneCheckBoxOutlineBlank;
  roundDoneOutline = roundDoneOutline;
  outlineMessage = outlineMessage;
  twotoneMessage = twotoneMessage;
  twotoneBuildCircle = twotoneBuildCircle;
  twotoneCheckBox = twotoneCheckBox;
  certificateIcon = certificateIcon;
  roundSubdirectoryArrowRight = roundSubdirectoryArrowRight;
  roundRepeat = roundRepeat;

  constructor(
    private cd: ChangeDetectorRef,
    private trans: TranslateService,
    private buildConfigService: BuildConfigService,
    private tasksService: TasksService,
    @Inject('CodeListController') private codeListController: Honeycomb.Tenant.LookupTables.IService.CodeListController,
  ) {
  
    if (!this.viewSort) {
      this.viewSort = new Honeycomb.Tenant.Tasker.IService.Model.TaskPageSort();
    } 

    const s = this.sortChanged.pipe(tap(_ => {
      setTimeout((function(){ this.setScrollbar(); }).bind(this), 10);
    })).subscribe(_ => null);
    this.subscriptions.push(s);

    this.tasksService.total.subscribe(t => { 
      this.itemsTotal = t; 
      this.cd.detectChanges();
    });

    this.loading$ = this.tasksService.loading;
    this.loading$.subscribe(s => {
      const progressElement = document.getElementById('tasks-progress');
      const tableElement = document.querySelector('mat-table');
      if (s && progressElement && tableElement) {
        progressElement.style.height = tableElement.scrollHeight + 'px';
      }
    });

    
    var sd = this.tasksService.data.subscribe(d => {
      this.dataSource.data = d;
      console.log('tasksService.data', d.length);
      this.setPaginator();
      this.cd.detectChanges();
      this.setScrollbar();
    });

    this.subscriptions.push(sd);
    
    var fpu = this.tasksService.forcePagerUpdate.pipe(take(1))
                  .subscribe(async s => await this.forcePagerUpdate());

    this.subscriptions.push(fpu);
  }

  async ngOnInit() {
    this.dataSource.data = this.tasks.getValue();
    this.itemsTotal = this.tasksService.totalStatic;
    this.pageSize = this.tasksService.pageSize(this.agendaKey);
    this.showPaginator.next(true);
    this.setScrollbar();
    this.setPaginator();

    if (this.buildConfigService.taskListRepairAttribute(TaskDetailComponent.ExternalSupplier, this.agenda)) {
      await this.loadAttribute(TaskDetailComponent.ExternalSupplier);
    }
    
    if (this.buildConfigService.taskListRepairAttribute(TaskDetailComponent.RepairStatus, this.agenda)) {
      await this.loadAttribute(TaskDetailComponent.RepairStatus);
    }

    if (this.buildConfigService.taskListRepairAttribute(TaskDetailComponent.RepairType, this.agenda)) {
      await this.loadAttribute(TaskDetailComponent.RepairType);
    }

    this.setScrollbar();
  }

  private async loadAttribute(attributeName: string) {
    this.attributes[attributeName] = await lastValueFrom(this.codeListController.DetailByName(attributeName)
    .pipe(
      take(1),
      map(cl => cl.codes),
      map(c => c.sort((a, b) => a.orderIndex - b.orderIndex)),
      map(c => c.map(cl => {
           return cl.codeValues[this.trans.getDefaultLang()] || cl.codeValues.default;
          }
        )
      )
    ));
  }

  
  getStateName(task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    return task.taskInfo.taskStatusCode;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private setPaginator() {

    if (!!this.paginator) {
      
      let filterCopy = this.tasksService.getFilterCopy();
      if (isNullOrUndefined(this.pageIndex)) {
        this.pageIndex = filterCopy.pageIndex ?? this.tasksService.pageIndex(this.agendaKey) ?? 0;
        this.pageSize = filterCopy.pageSize ??  this.tasksService.pageSize(this.agendaKey) ?? 10;
      }

      if (this.paginator.pageIndex === this.pageIndex) {
        console.log(`setPaginator - NOT CHANGED ${this.agendaKey}: pageIndex: ${this.pageIndex}, pageIndex: ${this.pageSize}`);
        return;
      }

      if (this.pageIndex > 0) {
        this.paginator.disabled = true;
        var startedOnZero = this.paginator.pageIndex === 0;
        this.paginator.pageIndex = this.pageIndex;
        if (startedOnZero) {
          this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored  
          this.paginator.previousPage(); // the only way how to light up correct button in paginator
        
        } else {
          this.paginator.previousPage(); // the only way how to light up correct button in paginator
          this.paginator.nextPage(); // - otherwise 1st is selected and page index is ignored
        }
        this.paginator.disabled = false;
      }

      console.log(`setPaginator ${this.agendaKey}: pageIndex: ${this.pageIndex}, pageIndex: ${this.pageSize}`);
      this.cd.detectChanges();
    }
  }

  public paginatorRangeLabel(page, size, length): string {
    if ((page + 1)   * size > length) {
      return `${page * size} - ${length}`;
    }
    return `${page * size} - ${(page + 1)   * size}`;
  }

  public setScrollbar() {
    if (this.stickyHorizontalScroll) {
      const sb = document.getElementById('scrollcontent');
      if (!!sb) {
        sb.style.width = document.querySelector('.mat-mdc-table').scrollWidth + 'px';
      }
    }
  }

  getUser(taskRelation: Honeycomb.Common.Enums.TaskRelation, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    const relatedUsers = task.taskUsers.filter(u => u.taskRelation === taskRelation);
    if (relatedUsers === null || relatedUsers.length === 0) {
      return [];
    }
    return relatedUsers;
  }

  async sortBy(column: string, isAttribute?: boolean) {
    if (!this.viewSort) {
      this.viewSort = await this.tasksService.getSort();
    } else {
      if (this.viewSort.column === column) {
        if (this.viewSort.direction === 'asc') {
          this.viewSort.direction = 'desc';
        } else if (this.viewSort.direction === 'desc') {
          this.viewSort.direction = 'asc';
        } else {
          this.viewSort.direction = 'desc';
        }
      } else {
        this.viewSort.column = column;
      }

      var col = availableCols(this.agenda).find(ac => ac.columnName.toLocaleLowerCase() == column.toLocaleLowerCase());
      isAttribute = !!col.isAttribute;
      this.tasksService.setSort(this.viewSort.column, this.viewSort.direction, isAttribute);
    }
    this.sortChanged.emit(this.viewSort);
    this.tasksService.load(this.agendaKey);
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      // this.paginator._intl.getRangeLabel = this.paginatorRangeLabel;
      this.setPaginator();
    }, 0);

    if (this.stickyHorizontalScroll) {
      const sb = document.getElementById('scrollbar');
      sb.addEventListener('scroll',
        (sbe: Event) => {
          if (this.isTouch) { return; }
          // CAUSES SHAKING TABLE on Android devices
          this.scrolling = true;
          document.querySelector('.mat-mdc-table').scrollLeft = (sbe.target as HTMLElement).scrollLeft;
          sbe.stopPropagation();
          sbe.cancelBubble = true;
          this.scrolling = false;
        });
      this.setScrollbar();

      const table = document.querySelector('.mat-mdc-table');
      table.addEventListener('scroll', (ts: Event) => {
        if (this.scrolling) { return; }
        this.scrolling = true;
        sb.scrollLeft = (ts.target as HTMLElement).scrollLeft;
        ts.stopPropagation();
        ts.cancelBubble = true;
        this.scrolling = false;
      });
    }
  }

  updateTask($event: MouseEvent, taskID: number) {
    $event.stopPropagation();
    $event.cancelBubble = true;
    $event.preventDefault();
    this.setTaskRead(taskID);
    this.selected.emit(taskID);
  }

  async pageChangedHandler(event: PageEvent) {
    if (this.paginator.disabled)  {
      return;
    }
    this.tasksService.setPager(this.agendaKey, event.pageIndex, event.pageSize);
    this.dataSource.data = await this.tasksService.load(this.agendaKey);
    this.itemsTotal = this.tasksService.totalStatic;
    this.pageSize = this.tasksService.pageSize(this.agendaKey);
    this.showPaginator.next(true);
  }


  public async forcePagerUpdate() {
    this.setPaginator();
  }

  getAttributeValue(attributeName: string, task: Honeycomb.Tenant.Tasker.IService.Model.Task) {
    let ta = task.taskAttributes.find(tta => tta.taskAttributeType.name.toLowerCase() === attributeName.toLowerCase());
    if (!ta) {
        return '';
    }

    if (!this.attributes[attributeName]) {
      return '';
    }
    let attr = this.attributes[attributeName].find(es => es.codeId.toString() === ta.value);
    if (!attr) {
      return '';
    }
    return attr.displayValue;
  }

  getPriorityName(priorityID: number) {
    const priorityName = Honeycomb.Common.Enums.Priority[priorityID];
    return priorityName;
  }

  setTaskRead(taskID: number)
  {
    const data: any[] = this.tasks.getValue();
    const task = data.find(task => task.taskID === taskID) || null;
    if (task !== null && !task.dateRead) 
    {
      task.dateRead = new Date();
    }
  }
}
